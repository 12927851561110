import { tokens } from '@dvd/tokens';

// Note: the following are constants that persist between the various navigation experiences.
export const BREAKPOINT_FULL = '979px';
export const BREAKPOINT_DRAWER = '820px';
export const BREAKPOINT_TINY = '600px';
export const TRANSITION_DURATION = '160ms'; // matches Legacy Caret

// TODO: abstract LOGIN_URL to handle dev/staging TLDs
export const LOGIN_URL =
  'https://app.wistia.com/auth/wistia_auth_sign_in_pre_auth';
export const SESSION_VALID_CHECK_URL =
  'https://app.wistia.com/session/verify_valid';

// NOTE: this is a _copy_ of Legacy Storefront's data, updated as needed to
// support the new navigation data structure for the Learning Center

// TODO: abstract this to a central place that both Reno & Legacy can access—in
// a performant manner
export const MENU_DATA = [
  {
    label: 'Product',
    id: 'product',
    accentColor: tokens.colors.blue500,
    secondaryColor: tokens.colors.blue100,
    href: '/product',
    trackingLabel: 'product-menu',
    trackingProperty: 'main-nav',
    tertiaryNav: [
      {
        label: 'Video management',
        id: 'hosting',
        icon: 'gear',
        children: [
          {
            href: '/product/hosting',
            name: 'Hosting',
            description: 'Upload and store all your videos.',
            trackingLabel: 'product-hosting',
            trackingProperty: 'main-nav',
          },
          {
            href: '/product/embeds',
            name: 'Embedding',
            description: 'Publish your videos wherever you want.',
            trackingLabel: 'product-embedding',
            trackingProperty: 'main-nav',
          },
          {
            href: '/product/player',
            name: 'Ad-free player',
            description: 'Fast playback without any distractions.',
            trackingLabel: 'product-ad-free-player',
            trackingProperty: 'main-nav',
          },
          {
            href: '/product/channels',
            name: 'Channels',
            description: 'Give your video series a home.',
            trackingLabel: 'product-galleries',
            trackingProperty: 'main-nav',
          },
          {
            href: '/product/localize',
            name: 'Localization',
            description: 'Dub and translate your videos in 30+ languages.',
            trackingLabel: 'product-localization',
            trackingProperty: 'main-nav',
          },
        ],
      },
      {
        label: 'Video marketing',
        id: 'marketing',
        icon: 'channel',
        children: [
          {
            href: '/product/webinar-hosting',
            name: 'Webinar hosting',
            description: 'Whip up seamless live events in no time.',
            trackingLabel: 'product-webinar-hosting',
            trackingProperty: 'main-nav',
          },
          {
            href: '/product/lead-generation',
            name: 'Lead generation',
            description: 'Amp up ROI with lead gen forms.',
            trackingLabel: 'product-lead-generation',
            trackingProperty: 'main-nav',
          },
          {
            href: '/product/video-seo',
            name: 'SEO',
            description: 'Get more eyeballs on your videos.',
            trackingLabel: 'product-seo',
            trackingProperty: 'main-nav',
          },
          {
            href: '/product/interactive-video',
            name: 'Engagement',
            description: 'Encourage interaction with your content.',
            trackingLabel: 'product-engagement',
            trackingProperty: 'main-nav',
          },
          {
            href: '/product/platform',
            name: 'Wistia API',
            description: 'Build custom video experiences for your site.',
            trackingLabel: 'product-platform',
            trackingProperty: 'main-nav',
          },
        ],
      },
      {
        label: 'Video creation',
        id: 'create',
        icon: 'camera',
        children: [
          {
            href: '/product/video-recording',
            name: 'Recording',
            description: 'Capture yourself, your screen, or both.',
            trackingLabel: 'product-recording',
            trackingProperty: 'main-nav',
          },
          {
            href: '/product/video-editing',
            name: 'Editing',
            description: 'Trim and polish videos in a snap.',
            trackingLabel: 'product-editing',
            trackingProperty: 'main-nav',
          },
          {
            href: '/product/collaboration',
            name: 'Collaboration',
            description: 'Your team’s feedback, all in one place.',
            trackingLabel: 'product-collaboration',
            trackingProperty: 'main-nav',
          },
        ],
      },
      {
        label: 'Video analytics',
        id: 'analytics',
        icon: 'stats',
        children: [
          {
            href: '/product/analytics',
            name: 'Analytics',
            description: 'All things video performance.',
            trackingLabel: 'product-analytics',
            trackingProperty: 'main-nav',
          },
        ],
      },
      {
        label: 'Video integrations',
        id: 'integrations',
        icon: 'integrations',
        children: [
          {
            href: '/product/marketing-automation',
            name: 'Marketing automation',
            description: 'Use video with HubSpot, Marketo, or Pardot',
            trackingLabel: 'product-marketing-automation',
            trackingProperty: 'main-nav',
          },
          {
            href: '/integrations',
            name: 'All integrations',
            description: 'See Wistia’s full integrations library.',
            trackingLabel: 'product-all-integrations',
            trackingProperty: 'main-nav',
          },
        ],
      },
    ],
    cta: {
      buttonText: 'Features Overview',
      buttonUrl: '/product',
      trackingLabel: 'product-features-overview',
      trackingProperty: 'main-nav',
    },
  },
  {
    label: 'Learning Center',
    id: 'learn',
    accentColor: tokens.colors.green600,
    secondaryColor: tokens.colors.green100,
    href: '/learn',
    trackingLabel: 'learning-center-menu',
    trackingProperty: 'main-nav',
    tertiaryNav: [
      {
        label: 'Resources',
        id: 'resources',
        icon: 'favorite',
        children: [
          {
            href: '/learn',
            name: 'Blog',
            description: 'Fresh video marketing insights.',
            trackingLabel: 'learning-center-blog',
            trackingProperty: 'main-nav',
          },
          {
            href: '/learn/showcase',
            name: 'Customer Stories',
            description: 'Get inspired by our creative customers.',
            trackingLabel: 'learning-center-customer-stories',
            trackingProperty: 'main-nav',
          },
          {
            href: '/resources/free-downloads',
            name: 'Asset Library',
            description: 'Free music, script templates, and more.',
            trackingLabel: 'learning-center-asset-library',
            trackingProperty: 'main-nav',
          },
        ],
      },
      {
        label: 'Watch',
        id: 'watch',
        icon: 'view-stream',
        children: [
          {
            href: '/watch',
            name: 'Video Tutorials',
            description: 'All of our learning videos in one place.',
            trackingLabel: 'learning-center-watch',
            trackingProperty: 'main-nav',
          },
          {
            href: '/events',
            name: 'Webinars',
            description: 'Live and VOD Wistia events for you.',
            trackingLabel: 'learning-center-webinars',
            trackingProperty: 'main-nav',
          },
          {
            href: '/series',
            name: 'Original Series',
            description: 'Our podcasts and branded shows.',
            trackingLabel: 'learning-center-view-all-our-series',
            trackingProperty: 'main-nav',
          },
        ],
      },
      {
        label: 'Guides',
        id: 'guides',
        icon: 'getting-started',
        children: [
          {
            href: '/about/state-of-video',
            name: 'State of Video Report 2025',
            description: 'Insights to help your business thrive.',
            trackingLabel: 'learning-center-state-of-video-2025',
            trackingProperty: 'main-nav',
          },
          {
            href: '/for/webinar-guide',
            name: 'Wistia Webinar Guidebook',
            description: 'Discover how to become a webinar wiz.',
            trackingLabel: 'learning-center-webinar-guidebook',
            trackingProperty: 'main-nav',
          },
          {
            href: '/about/video-marketing-guide',
            name: 'Guide to Video Marketing',
            description: 'Practical tips to elevate your video marketing.',
            trackingLabel: 'learning-center-guide-to-video-marketing',
            trackingProperty: 'main-nav',
          },
        ],
      },
      {
        label: 'Support',
        id: 'support',
        icon: 'help-center',
        children: [
          {
            href: 'https://support.wistia.com',
            name: 'Help Center',
            description: 'Need a hand? We’ve got you.',
            trackingLabel: 'learning-center-help-center',
            trackingProperty: 'main-nav',
          },
          {
            href: 'https://docs.wistia.com',
            name: 'Developer Docs',
            description: 'Use our APIs to create something unique.',
            trackingLabel: 'learning-center-developer-docs',
            trackingProperty: 'main-nav',
          },
        ],
      },
    ],
    cta: {
      buttonText: 'Learning Center overview',
      buttonUrl: '/learn',
      trackingLabel: 'learning-center-overview',
      trackingProperty: 'main-nav',
    },
  },
  {
    label: 'Pricing',
    id: 'pricing',
    accentColor: tokens.colors.purple600,
    href: '/pricing',
    trackingLabel: 'pricing-nav',
    trackingProperty: 'main-nav',
  },
  {
    label: 'Watch Demo',
    id: 'watch-demo',
    accentColor: tokens.colors.pink600,
    href: '/demo',
    trackingLabel: 'watch-a-demo-nav',
    trackingProperty: 'main-nav',
  },
];
